import { 
  addArticleToLocal,
  removeArticleFromLocal,
  getArticleFromLocal,
  getArticleRangeFromLocal
} from './LocalStore';

const API_BASE = '/api';
const GET_ARTICLE_LIST = 'article-list';
const GET_ARTICLE = 'article';
const GET_AUDIO = 'audio';

const INITIAL_PAGE_SIZE = 50;  // How many articles we will get for the first load.
const PAGE_SIZE = 10;  // How many articles we will get subsequent loads.

let homeArticleList = [];
let downloadedArticleList = [];
let apiConnectionGood = true;

/**
 * Get the article list from the API. 
 */
const getArticlesFromApi = bound => {
  const url = bound ? `${API_BASE}/${GET_ARTICLE_LIST}/${bound}`
                    : `${API_BASE}/${GET_ARTICLE_LIST}`;

  return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'}) 
    .then(response => {
      if (response.status === 200) {
        apiConnectionGood = true;
        return response.json().then(data => data);
      } else {
        apiConnectionGood = false;
        return Promise.resolve([]);
      }
    }).catch(error => {
      apiConnectionGood = false;
      return Promise.resolve([]);
    });
}

/**
 * Fill the article list for the home page (from the API).
 */
const fillHomeArticleList = async () => {
  let bound = homeArticleList.length > 0
              ? homeArticleList[homeArticleList.length - 1].id
              : 0;
  
  let articles = await getArticlesFromApi(bound);

  // Update downloaded flag
  await Promise.all(
    articles.map(
      article => getArticleFromLocal(article.id).then(
        localArticle => {
          if (localArticle) {
            article.downloaded = true;
          } else {
            article.downloaded = false;
          }
        }
      )
    )
  );

  homeArticleList = homeArticleList.concat(articles);
  return articles;                        
}

/**
 * Given its id, get the article from the API.
 */
const getArticleFromApi = id => {
  const url = `${API_BASE}/${GET_ARTICLE}/${id}`;
  return fetch(url, {method: 'GET', mode: 'cors', cache: 'no-cache'})
      .then(response => {
        if (response.status === 200) {
          apiConnectionGood = true;
          return response.json().then(data => data);
        } else {
          apiConnectionGood = false;
          return Promise.resolve(null);
        }
      }).catch(error => {
        apiConnectionGood = false;
        return Promise.resolve(null);
      });
}

/**
 * Get the URL to the audio file of an aticle
 */
const getAudioUrl = article => `${API_BASE}/${GET_AUDIO}/${article.id}`;

/**
 * If the article is downloaded, return the downloaded audio content.
 * If the article is not downloaded, return the URL of the audio file.
 */
const getAudio = article => 
  article.downloaded && article.audio 
    ? URL.createObjectURL(new Blob([article.audio])) 
    : getAudioUrl(article);

const getArticleIndex = (articles, id) => {
  if(!articles || articles.length === 0) {
    return -1;
  }
  
  for(let i=0;i<articles.length;i++) {
    if (articles[i].id === id) {
      return i;
    }
  }
  
  return -1;
};
  
const getArticlesNumber = articles => {
  return articles ? articles.length : 0; 
};
  
const getPrevious = (articles, article) => {
  if (!articles || !article) return false;
  const currentIndex = getArticleIndex(articles, article.id);
  return getArticleFromApi(articles[currentIndex - 1].id);
};
  
const getNext = (articles, article) => {
  if (!articles || !article) return false;
  const currentIndex = getArticleIndex(articles, article.id);
  return getArticleFromApi(articles[currentIndex + 1].id);
};

const getArticle = id =>
  getArticleFromLocal(id).then(
    article => article ? Promise.resolve(article) : getArticleFromApi(id)
  );

const hasPrevious = (articles, article) => {
  if (!articles || !article) return false;
  const currentIndex = getArticleIndex(articles, article.id);
  return currentIndex < 1 ? false : true;
}
  
const hasNext = (articles, article) => {
  if (!articles || !article) return false;
  const currentIndex = getArticleIndex(articles, article.id);
  return currentIndex < 0 || currentIndex + 1 >= getArticlesNumber(articles) 
         ? false : true;
}

const copyArticle = article => {
  if(!article) return null;
  return JSON.parse(JSON.stringify(article));
}

/**
 * Get a list of article, from the local store (IndexedDB).
 * Maximum of PAGE_SIZE articles are returned.
 * If bound is provided, only articles with an ID that is
 * less than bound are returned.
 */
const getDownloadedArticles = 
  bound => getArticleRangeFromLocal(bound, bound ? PAGE_SIZE : INITIAL_PAGE_SIZE);

/**
 * Fill the downloaded article list
 */
const fillDownloadedArticleList = async () => {
  const bound = downloadedArticleList.length > 0 
                ? downloadedArticleList[downloadedArticleList.length - 1].id
                : 0; 
  
  const articles = await getDownloadedArticles(bound);
  downloadedArticleList = downloadedArticleList.concat(articles);
  return articles;
}

/**
 * Initialize both of the article list (from API and local), and return the list for the home page.
 */
const initilizeArticleLists = async () => {
  await fillDownloadedArticleList();
  await fillHomeArticleList();
  return apiConnectionGood ? homeArticleList : downloadedArticleList;
}

/**
 * Return the article list for the home page
 */                
const getArticleListForHome = () => homeArticleList;

/**
 * Return the article list for the downloaded page
 */
const getArticleListForDownloaded = () => downloadedArticleList;

/**
 * Download an aritcle to local. The article will be added to the dowloaded article list too.
 */
const downloadArticle = 
  id => getArticleFromApi(id).then(
    article => {
      const index = findIndexForArticle(homeArticleList, article);
      homeArticleList[index].downloaded = true;
      article.downloaded = true;
      addArticleToArray(downloadedArticleList, article);
      addArticleToLocal(article)
    }
  );

/**
 * Remove a downloaded artical.
 */  
const removeDownloadedArticle = 
  id => getArticleFromLocal(id).then(
    article => {
      const index = findIndexForArticle(homeArticleList, article);
      removeFromArticleArray(downloadedArticleList, article);
      removeArticleFromLocal(id);
      if (index >= 0 && homeArticleList[index].downloaded) {
        homeArticleList[index].downloaded = false;
      } 
    }
  );
  

/**
 * Find the index of the first appearance of an article in an 
 * article array.
 */
const findIndexForArticle = (articleArray, article) => {
	 if (!articleArray || articleArray.length === 0 || !article) {
		 return -1;
	 }
		
	 for(let i=0;i<articleArray.length;i+=1) {
			if (articleArray[i].id === article.id) {
				 return i;
			}
		}
		
		return -1;
}
	
/**
 * Remove an article from an array.
 */
const removeFromArticleArray = (articleArray, article) => {
		let index = findIndexForArticle(articleArray, article);
		if (index > -1) {
			articleArray.splice(index, 1);
		}
}
	
/**
 * Add an article to the proper position of an array.
 * The array is sorted by id descendantly
 */
const addArticleToArray = (articleArray, article) => {
		let index = findIndexForArticle(articleArray, article);
		if (index > -1) {
			 return;
		}
		
		if (articleArray.length === 0 || articleArray[articleArray.length-1].id > article.id) {
			 articleArray.push(article);
		} else {
			 index = 0;
			 for (let i=0;i<articleArray.length;i+=1) {
				  if (articleArray[i].id < article.id) {
					  index = i;
					  break;
				  }
			  }
			
			  articleArray.splice(index, 0, article);
		}
}
    
export {
  initilizeArticleLists,
  getPrevious,
  getNext,
  getArticle,
  hasPrevious,
  hasNext,
  copyArticle,
  getArticleListForHome,
  getArticleListForDownloaded,
  downloadArticle,
  removeDownloadedArticle,
  fillHomeArticleList,
  fillDownloadedArticleList,
  getAudioUrl,
  getAudio,
  apiConnectionGood
};
