import './index.css'
import React from 'react';

const ArticleText = ({
    title,
    content
}) => 
  <>
    <div className="title">
      {title}
    </div>
    <div className="body" dangerouslySetInnerHTML={{__html: content}} />
  </>;

export default ArticleText;