import './index.css';
import React from 'react';
import Nav from '../Nav'

const Footer = ({
  showPreviousNext,
  onPreviousClick,
  onNextClick, 
  onHomeClick,
  onDownloadedClick,
  disablePrevious,
  disableNext,
  disableHome, 
  disableDownloads
}) =>
  <div className="footer" id="footer">
    <Nav 
      showPreviousNext={showPreviousNext}
      onPreviousClick={onPreviousClick}
      onNextClick={onNextClick}
      onHomeClick={onHomeClick}
      onDownloadedClick={onDownloadedClick}
      disablePrevious={disablePrevious}
      disableNext={disableNext}
      disableHome={disableHome}
      disableDownloads={disableDownloads}
    />  
  </div>

export default Footer;