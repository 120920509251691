import React from 'react';
import ArticleText from '../ArticleText';

const Article = ({
  article
}) =>
  <ArticleText 
    title={article.title}
    content={article.content} 
  />
  
export default Article;