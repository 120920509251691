import React from 'react';
import NavButton from './NavButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faHome,
  faArrowLeft,
  faArrowRight,
  faDownload
} from '@fortawesome/free-solid-svg-icons'

const Nav = ({
  showPreviousNext,
  onPreviousClick,
  onNextClick, 
  onHomeClick,
  onDownloadedClick,
  disablePrevious,
  disableNext,
  disableHome, 
  disableDownloads
}) => 
  <>
    {
      showPreviousNext
      ? <NavButton 
          onClick={onPreviousClick}
          disabled={disablePrevious}
        >
          <FontAwesomeIcon icon={faArrowLeft} /><br />Previous
        </NavButton>
      : null
    }
    <NavButton
      onClick={onHomeClick}
      disabled={disableHome}
      large={!showPreviousNext}
    >
      <FontAwesomeIcon icon={faHome} /><br />Home
    </NavButton>
    <NavButton
      onClick={onDownloadedClick}
      disabled={disableDownloads}
      large={!showPreviousNext}
    >
      <FontAwesomeIcon icon={faDownload} /><br />Downloads
    </NavButton>
    {
      showPreviousNext
      ? <NavButton
          onClick={onNextClick}
          disabled={disableNext}
        >
          <FontAwesomeIcon icon={faArrowRight} /><br />Next
        </NavButton>  
      : null  
    }     
  </>

export default Nav;