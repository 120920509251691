import './NavButton.css';
import React from 'react';

const NavButton = ({ 
  onClick, 
  disabled = false, 
  large = false,
  children }) => 
    disabled
    ? <button 
        type="button" 
        className={large ? 'nav-btn nav-btn-2 nav-btn-disabled' : 'nav-btn nav-btn-disabled'}
      >
        {children}
      </button>
    : <button 
        type="button" 
        className={large ? 'nav-btn nav-btn-2' : 'nav-btn'}
        onClick={onClick}
      >  
        {children}
      </button>

export default NavButton;    