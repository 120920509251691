import './index.css';
import React from 'react';
import ListItem from './ListItem'

const List = ({
  items, onClick
}) => 
    <table>
      <tbody>
      {
        items.map( 
          item => 
            <tr key={item.id}>
              <ListItem 
                id={item.id} 
                title={item.title} 
                created={item.created}
                downloaded={item.downloaded}
                onClick={onClick} 
              />
            </tr>)
      }
      </tbody>
    </table>

export default List;