import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faArrowLeft,
  faArrowRight,
  faSync
} from '@fortawesome/free-solid-svg-icons'

const PLAY_MODES = [
  'normal',   // Default mode - stop when current is over
  'loop',     // Loop mode - start again when current is over
  'forward',  // Forward mode - start next one when current is over
  'backward'  // Backward mode - start previous one when current is over
];

const getNextPlayMode = mode => {
  if (!mode || !PLAY_MODES.includes(mode) 
    || PLAY_MODES.indexOf(mode) === PLAY_MODES.length - 1) {
    return PLAY_MODES[0];
  }
  
  return PLAY_MODES[PLAY_MODES.indexOf(mode) + 1];
}

const getModeBtnIcon = mode => {
  switch(mode) {
    case 'loop':
      return <FontAwesomeIcon icon={faSync} />;
    case 'forward':
      return  <FontAwesomeIcon icon={faArrowRight} />;
    case 'backward':
      return  <FontAwesomeIcon icon={faArrowLeft} />; 
    default:   
      return 'Mode';
  }
}

export {
  PLAY_MODES,
  getNextPlayMode,
  getModeBtnIcon
};