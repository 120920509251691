import './index.css';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDownload,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { 
  downloadArticle,
  removeDownloadedArticle
} from '../../utils/UtilsArticle';

class DownloadButton extends Component {
  constructor(props) {
    super(props);

    /*
     * A 'key' attribute is added to the parent element 'ListItem',
     * so this element is rerendered when it is changed.
     */
    this.state = {
      downloaded: this.props.downloaded,
      id: this.props.id
    }

    this.download = this.download.bind(this);
    this.delete = this.delete.bind(this);
  }

  download(id) {
    downloadArticle(id);
    this.setState({downloaded: true});
  }

  delete(id) {
    removeDownloadedArticle(id);
    this.setState({downloaded: false});
  }

  render() {
    const {downloaded, id} = this.state;
    
    return (
      downloaded
      ? <button
          type="button"
          className="download-btn"
          onClick={() => this.delete(id)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      : <button
          type="button"
          className="download-btn"
          onClick={() => this.download(id)}
        >
          <FontAwesomeIcon icon={faDownload} />
        </button>    
    );
  }
}

export default DownloadButton;