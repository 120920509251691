import './index.css';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faPlay,
    faPause,
    faForward,
    faBackward
  } from '@fortawesome/free-solid-svg-icons'
import { getModeBtnIcon } from '../../utils/Global';
import { getAudio } from '../../utils/UtilsArticle';

class Player extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isPlaying: false,
      sliderPosition: 0
    }

    this.timeIntervalId = null;
  }

  componentDidMount = () => {
    this.player.src = getAudio(this.props.record);
    if (this.props.autoPlay) this.onPlayBtnClick();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.record.id !== prevProps.record.id) {
      this.player.src = getAudio(this.props.record);
      if (this.props.autoPlay) this.onPlayBtnClick();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timeIntervalId);
  }

  onPlayBtnClick = () => {
    this.player.play();

    // Clear previous interval if there is any.
    if (this.timeIntervalId) {
      clearInterval(this.timeIntervalId);
    }

    this.timeIntervalId = setInterval(() => {
      this.setState({
          isPlaying: true,
          sliderPosition: this.player.currentTime
      });
    }, 100);
  }

  onPauseBtnClick = () => {
    this.player.pause();
    clearInterval(this.timeIntervalId);
    this.setState({isPlaying: false});
  }

  OnForwardBtnClick = () => {
    const newPosition = this.state.sliderPosition + 3 > this.props.duration
                        ? this.props.duration
                        : this.state.sliderPosition + 3;
    this.player.currentTime = newPosition;
    this.setState({sliderPosition: newPosition});
  }

  onBackwardBtnClick = () => {
    const newPosition = this.state.sliderPosition > 3
                        ? this.state.sliderPosition - 3
                        : 0;
    this.player.currentTime = newPosition;
    this.setState({sliderPosition: newPosition});
  }

  onSliderChange = e => {
    const newPosition = e.target.value;
    this.player.currentTime = newPosition;
    this.setState({sliderPosition: newPosition});
  }

  formatSeconds = seconds => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);

    return min + ':' + (sec > 9 ? sec : '0' + sec);
  }

  onEnded = () => {
    this.player.pause();
    clearInterval(this.timeIntervalId);

    if (this.props.playMode === 'normal') {
      this.setState({
        isPlaying: false,
        sliderPosition: 0
      });
    } else if (this.props.playMode === 'loop') {
      this.onPlayBtnClick();
    } else if (this.props.playMode === 'forward' || this.props.playMode === 'backward') {
      this.props.onEnded();
    }
  }

  canPause = () => {
      return this.state.isPlaying && this.player && !this.player.paused;
  }

  render() {
    const { sliderPosition } = this.state;
    const { record, playMode, onPlayModeChange } = this.props;

    return (
      <div className="player">
        <audio onEnded={this.onEnded} ref={ref => this.player = ref} />
        <div className="player-header">
          <div className="player-buttons">
            {
              this.canPause()
              ? <button type="button" onClick={this.onPauseBtnClick}>
                  <FontAwesomeIcon icon={faPause} />
                </button>
              : <button type="button" onClick={this.onPlayBtnClick}>
                  <FontAwesomeIcon icon={faPlay} />
                </button>
            }
            <button id="btnForward" onClick={this.OnForwardBtnClick}>
              <FontAwesomeIcon icon={faForward} />
            </button>
            <button id="btnBackward" onClick={this.onBackwardBtnClick}>
              <FontAwesomeIcon icon={faBackward} />
            </button>
            <button id="btnMode" onClick={onPlayModeChange}>
              {getModeBtnIcon(playMode)}
            </button>
          </div>
          <div id="timeIndicator">
            <span id="currentTime">{this.formatSeconds(sliderPosition)}</span>/ 
            <span id="totalTime">{this.formatSeconds(record.duration)}</span>
          </div>
        </div>
        <input 
          type="range" 
          min="1" 
          max={record.duration} 
          value={sliderPosition} 
          id="slider"
          onChange={this.onSliderChange} 
        />
      </div>
    );
  }
}

export default Player;