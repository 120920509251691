import './index.css';
import React, { Component } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import List from '../List';
import {
  initilizeArticleLists,
  getPrevious, 
  getNext,
  getArticle,
  hasPrevious,
  hasNext,
  getArticleListForHome,
  getArticleListForDownloaded,
  fillHomeArticleList,
  fillDownloadedArticleList,
  apiConnectionGood
} from '../../utils/UtilsArticle';
import Article from '../Article';
import Player from '../Player';
import { getNextPlayMode } from '../../utils/Global';

class App extends Component {
  constructor() {
    super();
    this.state = {
      articles: [],
      currentArticle: null,
      playMode: 'normal',
      autoPlay: false,
      isHome: true
    };
  }

  loadingMoreArticles = false;

  componentDidMount = () =>
    initilizeArticleLists().then(list => 
      this.setState(
        {
          articles: list,
          isHome: apiConnectionGood
        }
      )
    );

  onArticleClick = id => {
    getArticle(id).then(a => {
      this.setState({currentArticle: a})
    });
  }

  onPreviousClick = () => {
    const { articles, currentArticle } = this.state;
    getPrevious(articles, currentArticle).then(a => this.setState({currentArticle: a}));
  }

  onNextClick = id => {
    const { articles, currentArticle } = this.state;
    getNext(articles, currentArticle).then(a => this.setState({currentArticle: a}));
  }

  onPlayEnded = () => {
    const { playMode } = this.state;
    if (playMode === 'forward') {
      this.onNextClick();
    } else if (playMode === 'backward') {
      this.onPreviousClick();
    }
  }

  onPlayModeChange = () => {
    const currentMode = this.state.playMode;
    const nextMode = getNextPlayMode(currentMode);
    const autoPlay = nextMode === 'normal' ? false : true;
    this.setState({
      playMode: nextMode,
      autoPlay: autoPlay
    });
  }

  onHomeClick = () => {
    this.setState({
      articles: getArticleListForHome(),
      currentArticle: null,
      isHome: true
    });
  }

  onDownloadedClick = () => {
    this.setState({
      articles: getArticleListForDownloaded(),
      currentArticle: null,
      isHome: false
    });
  }

  loadMoreArticles = () => {
    const contentDiv = document.querySelector('.content');
    const listTable = document.querySelector('table');
    if (contentDiv && listTable && contentDiv.scrollTop + contentDiv.clientHeight >= listTable.clientHeight - 80) {
      if (this.loadingMoreArticles === true) {
        return;
      }
      this.loadingMoreArticles = true;
      const existingArticles = this.state.articles;
      const isOnHome = this.state.isHome;
      if (isOnHome) {
        fillHomeArticleList().then(
          list => {
            this.setState({articles:existingArticles.concat(list)});
            this.loadingMoreArticles = false; 
          }
        )
      } else {
        fillDownloadedArticleList().then(
          list => {
            this.setState({articles:existingArticles.concat(list)});
            this.loadingMoreArticles = false; 
          }
        )
      }
    }
  }

  render() {
    const { articles, currentArticle, playMode, autoPlay } = this.state;

    return (
      <>
        <Header />
        {
          currentArticle
          ? <>
              <Player 
                record={currentArticle}
                playMode={playMode}
                autoPlay={autoPlay}
                onPlayModeChange={this.onPlayModeChange}
                onEnded={this.onPlayEnded}
              />
              <div className="content content-lower">
                <Article 
                  article={currentArticle}
                />
              </div>
            </>
          : <div className="content" onScroll= {this.loadMoreArticles}>
              <List 
                items={articles} 
                onClick={this.onArticleClick} 
              />
            </div>  
        }
        <Footer 
          showPreviousNext={currentArticle}
          onPreviousClick={this.onPreviousClick}
          onNextClick={this.onNextClick}
          onHomeClick={this.onHomeClick}
          onDownloadedClick={this.onDownloadedClick}
          disablePrevious={!hasPrevious(articles, currentArticle)}
          disableNext={!hasNext(articles, currentArticle)}
          disableHome={false}
          disableDownloads={false}
        />
      </>
    );
  }
}

export default App;