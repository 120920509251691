/**
 * IndexedDB related functions: provides the ability to save/manipulate article locally. 
 */
import { openDB } from 'idb';
import { copyArticle, getAudioUrl } from './UtilsArticle';

const DB_NAME = 'listen';
const DB_VERSION = 1;
const STORE_NAME = 'article';

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME, {keyPath: 'id'});
    }
  }  
});

/**
 * Save an article to the local store (IndexedDB).
 * The audio file of the article is downloaded and saved along with the article.
 */
const addArticleToLocal = article => dbPromise.then(
  db => downloadAudio(article).then(
    audio => {
      // Create a new copy of the article, so the article as an input parameter will not be updated.  
      const localArticle = copyArticle(article);
      localArticle.audio = audio;
      localArticle.downloaded = true;
      db.transaction(STORE_NAME, 'readwrite')
        .objectStore(STORE_NAME).add(localArticle);
      return localArticle;
    }
  )  
);

const getArticleFromLocal = id => dbPromise.then(
  db => db.transaction(STORE_NAME, 'readonly')
          .objectStore(STORE_NAME).get(id)
);

const removeArticleFromLocal = id => dbPromise.then(
  db => db.transaction(STORE_NAME, 'readwrite')
          .objectStore(STORE_NAME).delete(id)
);

const getArticleRangeFromLocal = (lastId, range) => dbPromise.then(
  async db => {
    const rv = [];
    let cursor = await db.transaction(STORE_NAME, 'readonly')
                         .objectStore(STORE_NAME)
                         .openCursor(null, 'prevunique')
                         .catch(e => {}); // Swallow the Safari error.
                                         
    let started = lastId ? false : true;
    while (cursor && rv.length < range) {
      if (started) {
        rv.push(cursor.value);
      } else {
        started = lastId === cursor.key;
      }
      cursor = await cursor.continue();
    }

    return rv;
  }
);

/**
 * Download the audio of a given article, return the audio arraybuffer that is downloaded.
 * Because Safari does not support persisting blob, use ArrayBuffer instead.
 */
const downloadAudio = article =>
  fetch(getAudioUrl(article), {method: 'GET', mode: 'cors', cache: 'no-cache'})
  .then(
    async response => {
      if (response.status === 200) {
        const data = await response.arrayBuffer();
        return data;
      } else {
        return Promise.resolve(null)
      }
    }).catch(error => {
      return null;
    });

export {
  addArticleToLocal,
  getArticleFromLocal,
  removeArticleFromLocal,
  getArticleRangeFromLocal
};


