import './ListItem.css';
import React from 'react';
import DownloadButton from '../DownloadButton';

const ListItem = ({
  id, title, created, downloaded, onClick
}) => 
    <>
      <td>
        <DownloadButton
          downloaded={downloaded}
          id={id}
          key={id + downloaded}
        />
      </td>
      <td>
        <button 
          type="button"
          onClick={() => onClick(id)}
          className="article-lnk"
        >
          {title} ({created})
        </button>
      </td>     
    </>

export default ListItem;